.footer{
    background-color: black;
    color: #fff;
    padding: 60px 0;
    background-image: url('../img/footer_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.join-us{
    padding: 20px 0 80px 0;
}

.join-us .container{
    max-width: 1400px;
    margin: 0 auto;
}

.join-us_title{
    text-align: center;
}

.join-us_title h2{
    font-size: 55px;
    text-transform: uppercase;
    color: rgb(226, 197, 240);
}

@media screen and (max-width: 375px) {
    .join-us_title h2 {
        font-size: 33px;
    }
}

.footer ul{
    list-style: none;
}

.footer ul li span{
    font-weight: bold;
}

.footer .wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.footer .info{
    flex: 0 0 33.3%;
    font-family: 'MavenPro-SemiBold';
}

.footer .news{
    flex: 0 0 33.3%;
}

.footer .news p{
    font-family: 'MavenPro-Regular';
}


.footer .contact-form{
    flex: 0 0 33.3%;
}

.footer .info-wrapper{
    padding: 30px;
}

.footer .news-wrapper{
   padding: 30px;
   background: #00000082;
}


.footer .contact-form_wrapper{
    padding: 30px;
}

.footer form{
    padding: 10px;
    font-family: 'MavenPro-SemiBold';
}

.footer .input{
    margin-bottom: 20px;
}

.footer .info-logo{
   
    display: block;
    text-align: left;
}

.footer .info-contant{
    margin-top: 20px;
}

.info-contant a{
    margin: 10px;
    
}

.info-contant a img{
    height: 54px;
}

.footer .info-wrapper h3{
    font-size: 35px;
    transform: skew(0deg) rotate(0deg);
  
}


@media screen and (max-width: 1200px) {
    .footer .wrapper{
        display: block;
    }

    .info-wrapper{
        text-align: center;
    }

    .glitch{
        text-align: center;
        font-size: 50px;
    }
}

@media screen and (max-width: 425px) {
    .join-us_title h2{
        font-size: 40px;
    }
}

@media screen and (max-width: 385px) {
    .info-contant a img{
        height: 45px;
    }
}
@media screen and (max-width: 375px) {
    .join-us_title h2{
        font-size: 35px;
    }
    .join-us{
        padding: 20px 0 0 0;
    }
}

