.navbar{
    padding: 5px 10px!important;
    margin-bottom: 20px!important;
    width: 100%!important;
    border-radius: 0px!important;
    z-index: 999!important;
    margin-bottom: 0px!important;
    transition: all 0.5s ease-in!important;
    /* background-color: #000000b0; */
    color: #fff!important;
    position: fixed!important;
}
.navbar2{
    padding: 5px 10px!important;
    margin-bottom: 20px!important;
    width: 100%!important;
    border-radius: 0px!important;
    z-index: 999!important;
    margin-bottom: 0px!important;
    transition: all 0.5s ease-in!important;
    box-shadow: 0 10px 33px rgb(0 0 0 / 10)!important;
    background-color: #000000;
    color: #fff!important;
    position: fixed!important;
}


  a{
      color: #fff!important;
  }

.navbar .container{
    padding: 10px;
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.navbar .logo img{
    width: 75%;
}

.navbar .navbar-list{
    display: block;
}

.navbar .navbar-list ul{
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}

.navbar .navbar-list ul li{
    margin-left: 20px;
    font-family: 'MavenPro-Regular';
    cursor: pointer;
}

.navbar .navbar-list ul li a {
    color: #fff;
    padding-bottom: 5px;
    transition: 1s ease-in;
    overflow: hidden;
    position: relative;
}

.navbar .navbar-list ul li a:hover::before {
    position: absolute;
    top: 100%;
    left: 0;
    content: '';
    width: 0%;
    height: 2px;
    background-color: rgb(174, 0, 255);
    animation: under-line 1.5s ease forwards;
    
}

.navbar2 .container{
    padding: 10px;
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.navbar2 .logo img{
    width: 75%;
}

.navbar2 .navbar-list{
    display: block;
}

.navbar2 .navbar-list ul{
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}

.navbar2 .navbar-list ul li{
    margin-left: 20px;
    font-family: 'MavenPro-Regular';
    cursor: pointer;
}

.navbar2 .navbar-list ul li a {
    color: #fff;
    padding-bottom: 5px;
    transition: 1s ease-in;
    overflow: hidden;
    position: relative;
}

.navbar2 .navbar-list ul li a:hover::before {
    position: absolute;
    top: 100%;
    left: 0;
    content: '';
    width: 0%;
    height: 2px;
    background-color: rgb(174, 0, 255);
    animation: under-line 1.5s ease forwards;
    
}

@keyframes under-line {
    from{
        width: 0;
    }
    to{
        width: 100%;
        color: rgb(174, 0, 255);
    }
}


.burger-menu{
    display: none;
}

.mobile-navbar-list{
    display: none;
}
.burger-menu_logo{
    width: 300px;
    height: 25px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 30px;
}
.burger-menu_logo img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 992px) {
    .navbar-list{
        display: none!important;
    }
    .burger-menu{
        display: block;
        font-size: 25px;
        z-index: 3;
    }

    .mobile-navbar-list ul{
        list-style: none;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0;
        z-index: 999;
    }
    .mobile-navbar-list{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        transform: translateX(100%);
        transition: 1s ease;
        overflow: hidden;
        background: linear-gradient(144deg, rgb(0 0 0) -32%, rgb(42 27 54) 32%, rgb(13 9 93) 100%); 
        
    }

    .mobile-navbar-list.active{
        transform: translateX(0%);
        
    }



    .mobile-navbar-list li{
        padding: 20px;
        font-size: 26px;
        font-family: 'MavenPro-Regular';
    }

    .mobile-navbar-list .bg-img{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 76%;
    opacity: 0.3;
    z-index: -1;
    }

    .mobile-navbar-list .bg-img img{
        width: 100%;
        height: 100%;
    }
    
}

