
.carousel-item{
   color: #fff;
   text-align: center;
}

.carousel-item p{
    padding: 30px 110px;
}

.carousel-indicators{
    display: none;
}



@media screen and (max-width: 970px) {
    .alice-carousel__prev-btn, .alice-carousel__next-btn{
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .carousel-indicators{
        display: flex;
        margin-bottom: 0;
    }
    .carousel-control-next, .carousel-control-prev{
        display: none;
    }

    .carousel-item p{
        padding: 30px 30px;
    }
}