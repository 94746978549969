
.team{
    padding: 150px 0 0;
    background-color: black;
    color: #fff;
    background-image: url('../img/main-bg.png');
    background-size: cover;
    
}

.team .container{
    max-width: 1400px;
    margin: 0 auto;
}

.team-title{
    background-image: url('../img/title_bg_2.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 20px 25px 190px;
    font-family: 'MavenPro-SemiBold';
    
}

.team-title h2{
    font-size: 55px;
    text-align: center;
    text-transform: uppercase;
    color: rgb(226, 197, 240);
 
}

.team-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team-item{
    flex: 0 0 25%;
    max-width: 25%;
    color: #fff;
    text-align: center;
    transition: 1s ease;
}

.team-item a{
    color: rgb(41, 41, 238)!important;
    font-weight: bold;
}

.team-item:hover{
    transform: translateY(-10px);
}

.team-item_wrapper{
    padding: 22px;
    font-family: 'MavenPro-Medium';
}

.team-item_img-one{
    width: 65%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/joel.png') no-repeat;
    background-size: cover;
    background-position: top;
}

.team-item_img-two{
    width: 65%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/lubomir.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.team-item_img-three{
    width: 65%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/oleg.png') no-repeat;
    background-size: cover;
    background-position: top;
}

.team-item_img-four{
    width: 65%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/ivan.png') no-repeat;
    background-size: cover;
    background-position: center;
}

@media screen and (max-width: 1300px) {
    .team-item{
        flex: 0 0 50%;
        max-width: 50%;
        color: #fff;
        text-align: center;
        transition: 1s ease;
    }
    .team-item_img-one{
        width: 35%;
        height: 190px;
        border: 1px solid #fff;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 10px;
        background: url('../img/joel.png') no-repeat;
        background-size: cover;
        background-position: top;
    }
    
    .team-item_img-two{
        width: 35%;
        height: 190px;
        border: 1px solid #fff;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 10px;
        background: url('../img/lubomir.png') no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .team-item_img-three{
        width: 35%;
        height: 190px;
        border: 1px solid #fff;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 10px;
        background: url('../img/oleg.png') no-repeat;
        background-size: cover;
        background-position: top;
    }
    
    .team-item_img-four{
        width: 35%;
        height: 190px;
        border: 1px solid #fff;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 10px;
        background: url('../img/ivan.png') no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media screen and (max-width: 1100px) {
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 30%;
        height: 140px;
    }
}

@media screen and (max-width: 1024px) {
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 30%;
        height: 130px;
    }
}

@media screen and (max-width: 930px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 35%;
        height: 130px;
    }
}

@media screen and (max-width: 860px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 38%;
        height: 130px;
    }
}

@media screen and (max-width: 790px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 38%;
        height: 130px;
    }
}

@media screen and (max-width: 768px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 50%;
        height: 160px;
    }
}

@media screen and (max-width: 745px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 45%;
        height: 135px;
    }
}

@media screen and (max-width: 700px) {
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 45%;
        height: 130px;
    }
}

@media screen and (max-width: 680px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 45%;
        height: 125px;
    }
}

@media screen and (max-width: 650px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item p{
        font-size: 14px;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 50%;
        height: 130px;
    }
}

@media screen and (max-width: 635px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 50%;
        height: 125px;
    }
}

@media screen and (max-width: 610px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 55%;
        height: 125px;
    }
}

@media screen and (max-width: 580px) {
    .team-title h2{
        font-size: 42px;
    }
    .team-item{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 45%;
        height: 220px;
    }
 }

 @media screen and (max-width: 540px) {
    .team-title h2{
        font-size: 42px;
    }
    .team-item{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 40%;
        height: 185px;
    }
 }

 @media screen and (max-width: 520px) {
    .team{
        padding: 120px 0 75px 0;
    }
    .team-item_img-one, .team-item_img-two, .team-item_img-three,  .team-item_img-four{
        width: 45%;
        height: 185px;
    }
}

 @media screen and (max-width: 480px) {
    .team-title h2{
        font-size: 42px;
    }
    .team-item{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 45%;
        height: 180px;
    }
 }

 @media screen and (max-width: 480px) {
  
    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 45%;
        height: 170px;
    }
 }

 @media screen and (max-width: 440px) {

    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 45%;
        height: 160px;
    }
 }

 @media screen and (max-width: 425px) {
    .team-title h2{
        font-size: 42px;
    }
    .team-item{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 50%;
        height: 165px;
    }
 } 
 @media screen and (max-width: 390px) {
    .team-item_img{
        height: 200px;
    }
    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 50%;
        height: 155px;
    }
 }

 @media screen and (max-width: 375px) {
    .team-item_img{
        height: 200px;
    }
    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 65%;
        height: 195px;
    }
 }

 
 @media screen and (max-width: 340px) {
    .team-item_img{
        height: 200px;
    }
    .team-item_img-one,.team-item_img-two,.team-item_img-three,.team-item_img-four,.team-item_img-five,.team-item_img-six{
        width: 65%;
        height: 165px;
    }
 }