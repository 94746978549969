.economy{
    background: #000;
    padding: 80px 0;
    background-image: url('../img/main-bg.png');
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.economy .container{
    max-width: 1400px;
    margin: 0 auto;
    
}

.economy .economy-title{
    background-image: url('../img/title_bg_1.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: rgb(226, 197, 240);
    padding: 40px 25px 125px;
}

.economy .economy-title h2{
    font-size: 55px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(226, 197, 240);
}
.economy .economy-title p{
    font-size: 24px;
    color: #fff;
    font-family: 'MavenPro-Regular';
}

.economy-earn{
    padding: 0 0 130px 0;
}

.economy-earn_info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.economy-earn_img{
    flex: 50%;
    max-width: 50%;
    border-radius: 25px;
}

.economy-earn_img img{
    max-width: 100%;
    height: auto;
    border-radius: 25px;
}

.economy-earn_text{
    text-align: left;
    flex: 50%;
    max-width: 50%;
    color: #fff;
    padding: 30px;
   
}

.economy-earn_text h4{
    text-align: center;
    font-size: 27px;
    text-transform: uppercase;
    padding-bottom: 15px;
    border-bottom: 3px solid rgb(144, 0, 255);
}

.economy-earn_text p{
    font-size: 23px;
    margin-top: 20px;
    padding: 35px;
    font-family: 'MavenPro-Regular';
}

.economy-earn_text span{
    /* border-radius: 50%; */
    padding: 10px;
    text-align: center;
    padding: 15px;
    font-weight: bold;
}

.economy-earn_text .circle1{
    color: rgb(0, 255, 255);
    border: 6px solid rgb(0, 255, 255);
}
.economy-earn_text .circle2{
    color: rgb(162, 0, 255);
    border: 6px solid rgb(162, 0, 255);
}

.economy-meta{
    padding: 50px 20px;
}

.economy-meta_title{
    text-align: center;
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
    padding-bottom: 15px;
    border-bottom: 3px solid rgb(144, 0, 255);
    color: rgb(218, 180, 248);
}


.economy-meta_list{
    padding: 10px 10px;
    color: #fff;
}

.economy-meta_list ul{
   list-style: none;
   font-family: 'MavenPro-Regular';
}

.economy-meta_list li{
    margin-top: 15px;
    padding: 10px 25px;
    font-size: 24px;
    position: relative;
}

.economy-meta_list li::before {
    content: "";
    background: rgb(144, 0, 255);
    width: 20px;
    height: 5px;
    position: absolute;
    left: -1%;
    top: 24px;
}
.economy-diagram{
    max-width: 800px;
    margin: 0 auto;
}
.economy-diagram img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 855px){
    .economy-earn_text p{
        font-size: 20px;
        padding: 25px;
    }
}


@media screen and (max-width: 768px){
    .economy{
        padding: 80px 0 0 0;
    }
   
    .economy-earn{
        padding: 10px 0;
    }
    .economy-earn_text h4{
        font-size: 25px;
    }

    svg, svg:hover{
        box-shadow: none;    
    }

    .economy-earn_img, .economy-earn_text{
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 460px){
    .economy-earn_text p{
        font-size: 20px;
        padding: 25px;
    }
}




@media screen and (max-width: 425px) {
    .economy{
        padding: 0;
    }

    .economy-meta{
        padding: 50px 0;
    }
    .economy .economy-title h2{
        font-size: 40px;
    }
    .economy-earn_img, .economy-earn_text{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .economy-meta_list{
        padding: 10px 10px 0 30px;
    }
    .economy-meta_list li{
        font-size: 20px;
    }
    .economy-meta_list li::before {
        left: -4%;
    }

    .economy-meta_list ul{
        padding-left: 0;
     }

     .economy-earn_text p{
        font-size: 20px;
        margin-top: 20px;
        padding: 20px;
        font-family: 'MavenPro-Regular';
    }
    
    .economy-earn_text span{
        padding: 10px;
        text-align: center;
        padding: 15px;
        font-weight: bold;
    }
    
    .economy-earn_text .circle1{
        color: rgb(0, 255, 255);
        border: 6px solid rgb(0, 255, 255);
    }
    .economy-earn_text .circle2{
        color: rgb(162, 0, 255);
        border: 6px solid rgb(162, 0, 255);
    }
 }

 @media screen and (max-width: 400px){
    .economy-earn_text p{
        font-size: 18px;
        padding: 18px;
    }
}


 @media screen and (max-width: 375px) {
    .economy-earn_text p{
        font-size: 16px;
    }

    .economy-earn_text span{
        padding: 10px;
    }

    .economy-earn_text h4{
        font-size: 21px;
    }
    .economy-meta_list li{
        font-size: 20px;
    }
 }

 @media screen and (max-width: 350px){
    .economy-earn_text p{
        font-size: 14px;
        padding: 10px;
    }
}