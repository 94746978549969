.roadmap{
    background-image: url('../img/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000000;
    position: relative;
    padding-top: 0;
}


.roadmap .container{
    max-width: 1400px;
    margin: 0 auto;
}

.roadmap .roadmap-title{
    text-align: center;
    width: 100%;
    margin: 0 auto;
    color: rgb(222, 177, 243);
    background-image: url('../img/title_bg_2.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 20px 25px 190px;

   
}

.roadmap .roadmap-title h2{
    font-size: 55px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgb(226, 197, 240);
}


.roadmap-items{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.roadmap-block-title{
    color: rgb(222, 177, 243);
}

.roadmap-time{
    text-align: right;
    color: #fff;
}
.roadmap-item::before{
    content: '';
    background: rgb(144, 0, 255);
    position: absolute;
    height: 100%;
    width: 3px;
    top: 0;
    left: -10%;
}

.roadmap-item{
    width: 50%;
    margin-bottom: 20px;
    border: 5px solid rgb(144, 0, 255);
    border-radius: 25px;
    position: relative;
    box-shadow: 0 0rem 1rem rgb(0 0 0 / 10%);
    transition: all 1s ease;
}

.roadmap-item:hover{
    border: 5px solid rgb(0, 204, 255);
}

.roadmap .poadmap-content ul{
    list-style: none;
    color: #fff;
    font-family: 'MavenPro-Regular';
}
.roadmap .poadmap-content ul li{
    padding: 5px;
    font-size: 20px;
}



.roadmap-wrapper::before{
    content: '';
    position: absolute;
    top: -9%;
    left: -10.6%;
    width: 10px;
    height: 10px;
    background: rgb(144, 0, 255);
    border-radius: 50%;
    
}

.roadmap-item::before:hover{
    background: rgb(0, 204, 255);
    color: #fff;
    cursor: default;
}

.roadmap-wrapper{
    padding: 30px 20px;
}

@media screen and (max-width: 850px) {
    .roadmap-item{
        width: 75%;
    }
}

@media screen and (max-width: 810px) {
    .roadmap .poadmap-content ul li{
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .roadmap .roadmap-title{
        width: 100%;
    }
}

@media screen and (max-width: 650px){
    .roadmap-wrapper::before{
        display: none;
    }

    .roadmap-item::before{
        display: none;
    }

    .roadmap-item{
        width: 90%;
    }
}


@media screen and (max-width: 425px){
    .roadmap{
        padding-top: 0;
    }
    .roadmap-wrapper::before,.roadmap-item::before{
        display: none;
    }
    .roadmap-item{
        width: 80%;
    }
    .roadmap-wrapper{
        padding: 30px 10px;
    }
    .roadmap .poadmap-content ul li{
        font-size: 14px;
    }
}

@media screen and (max-width: 375px){
    .roadmap .roadmap-title h2{
        font-size: 42px;
    }
    .roadmap .roadmap-title{
        padding: 20px 25px 100px;
    }
    .roadmap-item{
        width: 95%;
    }
}