
.features{
    background-color: rgb(0, 0, 0);
    background-image: url('../img/main-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    
 
}

.features .container{
    border-radius: 20px;
    max-width: 1400px;
    margin: 0 auto;
}

.features-content{
    display: flex;
    justify-content: center;
}
.features-wrapper{
    padding: 30px 50px;
}

.features-content h3{
    margin-bottom: 25px;
    font-size: 24px;
    color: #fff;
}

.features-wrapper ul{
    list-style: none;
    font-family: 'MavenPro-Regular';
}

.features-wrapper ul li{
    font-size: 24px;
    padding: 7px 0 17px 40px;
    position: relative;
    color: #fff;
}

.features-wrapper ul li::before{
    content: "";
    background: rgb(174, 0, 255);
    width: 20px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 24px;
}

.features-box{
    flex: 0 0 44%;
    max-width: 44%;
}

.features-img{
    flex: 0 0 56%;
    max-width: 56%;
}


.features-img img{
    width: 100%;
    height: auto;
    border-radius: 25px;
}

.carousel-control-next, .carousel-control-prev {
    top: 0%;
}


.features .btn{
    margin-top: 30px;
    transition: 0.5s ease;
}

.features .btn:hover{
    transform: translateY(-10px);
}

@media screen and (max-width: 970px) {
    .features-content{
        flex-direction: column;
    }

    .features-box{
        flex: 0 0 100%;
        max-width: 100%;
    }

    .features-img{
        max-width: 100%;
        padding: 20px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 540px) {
    .features{
        padding: 0;
    }
    .features-content{
        display: block;
    }
    .features-box{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .features-img{
        flex: 0 0 100%;
        max-width: 100%;
    }

    .features-wrapper ul{
        padding-left: 0;
        
    }
}

@media screen and (max-width: 425px) {
    .features-wrapper{
        padding: 30px 30px;
    }
    .features-wrapper ul li{
        font-size: 20px;
    }
}

@media screen and (max-width: 375px) {
    .features-wrapper ul li{
        font-size: 20px;
    }
}


