


.forwhom-content{
    /* background-image: url('../img/title_bg_2.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 20px 25px 10px;
}

.forwhom-content h2{
    font-size: 55px;
    font-weight: bold;
    padding: 20px 20px;
    text-transform: uppercase;
    color: rgb(226, 197, 240);
}



.forwhom .forwhom-content{
    color: #fff;
}





.athors{
    background: #000;
    background-image: url(https://wallpapercave.com/dwp1x/wp2831956.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 0;
}

.athors .container{
    max-width: 1400px;
    margin: 0 auto;
}

.athors-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.athors-item{
    flex: 0 0 25%;
    max-width: 25%;
    color: #fff;
    text-align: center;
    transition: 1s ease;
}

.athors-item:hover{
    transform: translateY(-10px);
}

.athors-item_wrapper{
    padding: 20px;
    font-family: 'MavenPro-Medium';
}

.athors-item_img-one{
    width: 100%;
    height: 240px;
    background-image: url(../img/artist.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 10px;
}
.athors-item_img-two{
    width: 100%;
    height: 240px;
    background-image: url(../img/nft.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 10px;
}
.athors-item_img-three{
    width: 100%;
    height: 240px;
    background-image: url(../img/producers.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 10px;
}
.athors-item_img-four{
    width: 100%;
    height: 240px;
    background-image: url(../img/gamers.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 10px;
}

@media screen and (max-width: 970px) {
    .athors-item{
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 425px) {
    .athors{
        padding-top: 0;
    }
    .forwhom .forwhom-content h2{
        font-size: 40px;
    }
   .athors-item{
       flex: 0 0 100%;
       max-width: 100%;
   }
}

.space-img{
    width: 30%;
    height: 100%;
    position: absolute;
    top: 20%;
    right: 0;
    overflow: hidden;
}

.space-img img{
    width: 100%;
    height: 100%;
    
}

@media screen and (max-width: 1100px) {
    .space-img{
        height: 70%;
    }
}

@media screen and (max-width: 1000px) {
    .space-img{
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .forwhom-content h2{
        font-size: 45px;
    }
}

@media screen and (max-width: 375px) {
    .forwhom-content h2{
        font-size: 42px;
    }
}