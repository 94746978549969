
.advisors{
    padding: 150px 0;
    background-color: black;
    color: #fff;
    background-image: url('../img/main-bg.png');
    background-size: cover;
    
}

.advisors .container{
    max-width: 1400px;
    margin: 0 auto;
}

.advisors-title{
    background-image: url('../img/title_bg_1.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 20px 25px 190px;
    font-family: 'MavenPro-SemiBold';
    
}

.advisors-title h2{
    font-size: 55px;
    text-align: center;
    text-transform: uppercase;
    color: rgb(226, 197, 240);
 
}

.advisors-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.advisors-item{
    flex: 0 0 33.3%;
    max-width: 33.3%;
    color: #fff;
    text-align: center;
    transition: 1s ease;
}

.advisors-item a{
    color: rgb(28, 28, 235)!important;
    font-weight: bold;
}

.advisors-item:hover{
    transform: translateY(-10px);
}

.advisors-item_wrapper{
    padding: 35px;
    font-family: 'MavenPro-Medium';
}

.advisors-item_img-one{
    width: 50%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/alex.jpg') no-repeat;
    background-size: cover;
    background-position: top;
}

.advisors-item_img-two{
    width: 50%;
    height: 190px;
   
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/metaproject.jpg') no-repeat;
    background-size: 70%;
    background-position: center;
}

.advisors-item_img-three{
    width: 50%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/jeff.jpg') no-repeat;
    background-size: cover;
    background-position: top;
}

.advisors-item_img-four{
    width: 50%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/eric.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.advisors-item_img-five{
    width: 50%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/ogar.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.advisors-item_img-six{
    width: 50%;
    height: 190px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: url('../img/carl.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

@media screen and (max-width: 1400px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        max-width: 45%;
        height: 175px;
    }
}

@media screen and (max-width: 1389px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        max-width: 45%;
        height: 170px;
    }
}

@media screen and (max-width: 1300px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        max-width: 45%;
        height: 155px;
    }
}

@media screen and (max-width: 1200px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        max-width: 45%;
        height: 140px;
    }
}

@media screen and (max-width: 1140px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        max-width: 45%;
        height: 135px;
    }
}

@media screen and (max-width: 1080px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 110px;
    }
}


@media screen and (max-width: 1024px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 45%;
        height: 115px;
    }
}

@media screen and (max-width: 950px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 95px;
    }
}

@media screen and (max-width: 910px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 90px;
    }
}

@media screen and (max-width: 850px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 125px;
    }
    .advisors-item{
        flex: 0 0 50%;
        max-width: 50%;
        color: #fff;
        text-align: center;
        transition: 1s ease;
    }
    .advisors-item_img{
        width: 100%;
        height: 215px;
    }
}

@media screen and (max-width: 768px) {
    .advisors{
        padding: 120px 0 75px 0;
    }
    .advisors-item{
        flex: 0 0 50%;
        max-width: 50%;
        color: #fff;
        text-align: center;
        transition: 1s ease;
    }
    .advisors-item_img{
        width: 100%;
        height: 215px;
    }
}

@media screen and (max-width: 740px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 115px;
    }
}

@media screen and (max-width: 715px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 105px;
    }
}

@media screen and (max-width: 655px) {
    .advisors-title{
        padding: 20px 25px 115px;
    }
    .advisors-item p{
        font-size: 14px;
    }
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 90px;
    }
}

@media screen and (max-width: 600px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 90px;
    }
}

@media screen and (max-width: 580px) {
    .advisors-title h2{
        font-size: 42px;
    }
    .advisors-item{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 70%;
        height: 215px;
    }
}

@media screen and (max-width: 550px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 70%;
        height: 205px;
    }
}

@media screen and (max-width: 550px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 40%;
        height: 170px;
    }
}

@media screen and (max-width: 500px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 50%;
        height: 170px;
    }
}

@media screen and (max-width: 490px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 42%;
        height: 155px;
    }
}

@media screen and (max-width: 475px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 45%;
        height: 165px;
    }
}

@media screen and (max-width: 450px) {
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 45%;
        height: 160px;
    }
}

@media screen and (max-width: 425px) {
    .advisors-title{
        padding: 20px 25px 90px;
    }
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 42%;
        height: 130px;
    }
}


 @media screen and (max-width: 380px) {
    .advisors-title{
        padding: 20px 25px 60px;
    }
    .advisors-item_img{
        height: 200px;
    }
    .advisors-item_img-one,.advisors-item_img-two,.advisors-item_img-three,.advisors-item_img-four,.advisors-item_img-five,.advisors-item_img-six{
        width: 45%;
        height: 120px;
    }
 }