.product{
    padding: 40px 0 50px;
    background: #000;
    background-image: url('../img/title_bg_2.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.product .container {
    max-width: 1400px;
    margin: 0 auto;
}

.product .product-content{
    color: #fff;
    text-align: center;
}

.product .product-content h2{
    font-size: 55px;
    font-weight: bold;
    padding: 20px 0;
    text-transform: uppercase;
    color: rgb(226, 197, 240);
    font-family: 'MavenPro-Medium';
}

.product .product-content p{
    font-size: 24px;
    font-family: 'MavenPro-Regular';
}

@media screen and (max-width: 425px){
    .product .product-content h2{
        font-size: 41px;
    }
}

@media screen and (max-width: 375px){
    .product .product-content p{
        font-size: 20px;
        font-family: 'MavenPro-Regular';
    }
}

