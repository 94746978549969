.main{
    width: 100%;
    height: 720px;
    overflow: hidden;
    position: relative;
}

#mainVideo{
    object-position: center 100%;
    min-width: 100%;
    display: block;
    height: 100vh;
    max-width: none;
    min-height: 100vh;
    object-fit: cover;
    width: 100%;
    position: absolute;
    z-index: 0;
}

.main .bg-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 15px;
}

.main .bg-bottom img{
    max-width: 100%;
}

.main .main-title{
    height: 100%;
}
.main .main-title .container{
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
}

.title-items{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(0%);
}

.title-content{
    padding: 35px;
    max-width: 60%;
    transition: 1s ease;
    font-family: 'MavenPro-SemiBold';
}

.title-content .title h3{
    text-transform: uppercase;
    font-weight: bold;
    padding: 30px 0 0 0;
   
}



.main .main-title{
    color: rgb(255, 255, 255);
    z-index: 30;
    text-align: center;
}



.title-content{
    background-image: url('../img/logo_bg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}


  
.main .title-content h3{
    font-size: 36px;
}
.main .main-title p{
    font-size: 20px;
    font-weight: 400;
    padding: 25px 0;
}

.main .video{
    font-size: 50px;
    margin: 30px auto;
  

}
.main .video i{
    animation: pulse 2s infinite ease;
    border-radius: 50%;
    cursor: pointer;
}

@keyframes pulse {
    from{
        box-shadow: 0px 0px 10px red;
        color: #fff;
       
    }
    to{
        box-shadow: 0px 0px 100px red;
        color: rgb(255, 0, 0);
   
    }
}

@media screen and (max-width: 1285px) {
  .glitch span{
      display: none;
  }

  .glitch{
    font-size: 4rem;
  }
}

@media screen and (max-width: 1055px) {
 
    .glitch{
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .title-content{
        padding: 30px;
    }
    .main .main-title p{
        font-size: 16px;
        padding: 10px 0;
    }
  }

  @media screen and (max-width: 910px) {
 
    .glitch{
      font-size: 3rem;
    }

    .main .main-title p{
    font-size: 16px;
    font-weight: 400;
    padding: 6px 0px 40px;
    }
  }

  @media screen and (max-width: 838px) {
    .main{
        height: 660px;
    }
    .glitch{
        font-size: 40px;
      }

      .main .title-content{
          padding: 0;
      }

      .main .title-content h3{
        font-size: 27px;
      }
      .main .main-title p{
        font-size: 14px;
        font-weight: 400;
        padding: 0px 3px 26px;
      }
  }


  @media screen and (max-width: 768px) {
    .main{
        height: 660px;
    }
    .glitch{
        font-size: 40px;
      }

      .main .title-content{
          padding: 0;
      }

      .main .title-content h3{
        font-size: 27px;
      }
      .main .main-title p{
        font-size: 14px;
        font-weight: 400;
        padding: 0px 3px 26px;
      }
  }

  @media screen and (max-width: 738px) {
  
      .main .title-content h3{
        font-size: 24px;
      }
      .main .title-content h1{
          font-size: 45px;
      }
    
  }

  @media screen and (max-width: 667px) {
    .main .main-title h1{
        font-size: 40px;
        font-weight: 900;
        margin: 0;
        padding-bottom: 0;
    }
    .main .main-title h3{
        font-size: 18px;
    }
    .main .main-title p{
        font-size: 15px;
        font-weight: 400;
        padding: 10px 30px 15px;
    }
}

@media screen and (max-width: 596px) {
    .main .main-title h1{
        font-size: 38px;
        font-weight: 900;
        margin: 0;
        padding-bottom: 0;
    }
    .main .main-title h3{
        font-size: 18px;
    }
    .main .main-title p{
        font-size: 15px;
        font-weight: 400;
        padding: 10px 30px 15px;
    }
}


@media screen and (max-width: 567px) {
    .main .main-title h1{
        font-size: 36px;
        font-weight: 900;
        margin: 0;
        padding-bottom: 0;
    }
    .main .main-title h3{
        font-size: 18px;
    }
    .main .main-title p{
        font-size: 15px;
        font-weight: 400;
        padding: 10px 30px 15px;
    }
}

@media screen and (max-width: 540px) {
    .main{
        height: 100vh;
    }
    .title-content{
        max-width: 100%;
    }

    .main .main-title p{
        padding: 10px 87px 15px;
    }
}


@media screen and (max-width: 425px) {
    .main .main-title h1{
        font-size: 44px;
        font-weight: 900;
        margin: 0;
        padding-bottom: 0;
    }
    .main .main-title h3{
        font-size: 18px;
    }
    .main .main-title p{
        font-size: 15px;
        font-weight: 400;
        padding: 10px 74px 15px;
    }
}

@media screen and (max-width: 402px) {
    .main .main-title h1{
        font-size: 40px;
    }
    .main .main-title h3{
        font-size: 22px;
    }
    .main .main-title p{
        font-size: 14px;
        padding: 10px 40px 15px;
       
    }
}

@media screen and (max-width: 375px) {
    .main .main-title h1{
        font-size: 35px;
    }
    .main .main-title h3{
        font-size: 19px;
    }
    .main .main-title p{
        font-size: 14px;
        padding: 10px 40px 15px;
       
    }
}

@media screen and (max-width: 367px) {
    .main .main-title h1{
        font-size: 32px;
    }
    .main .main-title h3{
        font-size: 19px;
    }
    .main .main-title p{
        font-size: 12px;
       
    }
}

@media screen and (max-width: 320px) {
    .main .main-title h1{
        font-size: 32px;
    }
    .main .main-title h3{
        font-size: 19px;
    }
    .main .main-title p{
        font-size: 12px;
       
    }
}