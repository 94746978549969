.diagram{
    background-color: rgb(0, 0, 0);
    margin: 0 auto;
}

text:not(.google-visualization-tooltip text){
    text-anchor: start;
    font-family: Arial;
    font-size: 15px;
    stroke: none;
    stroke-width: 0;
    fill: rgb(255, 255, 255);
}

svg{
    box-shadow: 0px 0px 20px #535050;
    transition: 2s ease;
}

svg:hover{
    box-shadow: 0px 0px 50px rgb(174, 0, 255);
}
