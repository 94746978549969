.work{
background-color: rgb(0, 0, 0);
background-image: url('../img/main-bg.png');
background-size: cover;
background-repeat: no-repeat;
}

.work .container{
    max-width: 1400px;
    margin: 0 auto;
}

.work .work-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.work .work-content{
    flex: 0 0 50%;
    max-width: 50%;
    color: #fff;
}

.work-content_wrapper{
    padding: 30px;
}

.work .work-content h3{
    color: rgb(226, 197, 240);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    font-family: 'MavenPro-Medium';
}

.work .work-content p{
    margin-top: 20px;
    font-size: 20px;
    font-family: 'MavenPro-Regular';
}

.work-slider{
    flex: 0 0 50%;
    max-width: 50%;
}

.work-slider_wrapper{
    padding: 20px;
    height: 100%;
}

.work-slider_wrapper p{
    font-weight: 400;
    text-align: center;
    color: #fff;
    font-size: 18px;
    width: 75%;
    margin: 0 auto;
    padding-top: 25px;
}

.work-slider_wrapper img{
    width: 100%;
    height: auto;
    border-radius: 25px;
}

@media screen and (max-width: 970px) {
    .work .work-content, .work-slider{
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .work{
        padding-bottom: 0;
    }
    .work-content_wrapper{
        padding: 10px;
    }
    .work .work-content h3{
        font-size: 27px;
    }
}

@media screen and (max-width: 375px) {
    .work .work-content h3{
        font-size: 22px;
    }

    .work .work-content p{
        font-size: 18px;
    }
}

